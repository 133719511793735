import React, { useState } from "react";

import Layout from "./components/layout/layout";
import InfoBlock from "./components/infoBlock/infoBlock";
import SearchBlock from "./components/searchBlock/searchBlock";
import BoatsListTable from "./components/tables/boatListTable";
import ContainerListTable from "./components/tables/containerListTable";
import ContainerMovementsTable from "./components/tables/containerMovementsTable";

import { getInfo, getContainerMovements } from "./services/services";
import Modal from "./components/modal/modal";
import Loader from "./components/loader/loader";
import Timeline from "./components/timeline/timeline";

function App() {
  const [data, setData] = useState({
    showInfo: true,
    boats: [],
    containers: [],
    lastMovement: {
      date: new Date()
    },
    searchPattern: ""
  });
  const [modal, setModal] = useState({ display: false, data: [] });
  const [fetching, setFetching] = useState(false);

  async function onSearch({ booking, bill, container }) {
    setFetching(true);
    const { boats, containers } = await getInfo({
      booking,
      bill,
      container
    });
    setFetching(false);
    setData({
      showInfo: false,
      boats,
      containers,
      lastMovement: {
        date: new Date()
      },
      searchPattern: {
        booking,
        bill,
        container
      }
    });
  }

  async function onSearchContainer(container) {
    setFetching(true);
    const containerMovements = await getContainerMovements(
      container.containerNumber
    );
    setFetching(false);
    setModal({ display: true, data: containerMovements });
  }
  return (
    <Layout>
      {fetching && <Loader />}
      <SearchBlock onSearch={onSearch} />
      {data.showInfo ? (
        <InfoBlock />
      ) : data.boats.length === 0 ? (
        <div className="no-results">
          <h1>Sin resultados</h1>
        </div>
      ) : (
        <>
          <Timeline
            searchPattern={data.searchPattern}
            boats={data.boats}
            lastMovement={data.lastMovement.date}
          />
          <BoatsListTable
            boats={data.boats}
            lastMovement={data.lastMovement.date}
          />
          <ContainerListTable
            containers={data.containers}
            onClickContainer={onSearchContainer}
          />
          {modal.display && (
            <Modal closeModal={() => setModal({ display: false, data: [] })}>
              <ContainerMovementsTable containerMovements={modal.data} />
            </Modal>
          )}
        </>
      )}
    </Layout>
  );
}

export default App;
