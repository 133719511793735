import React from "react";

import { parseDate } from "../../utils/date";

import ContainerImg from "../../assets/img/container_marguisa.jpg";

import "./tables.style.scss";

export default function ContainerListTable({ containers, onClickContainer }) {
  return (
    <div className="table-block">
      <div className="bar" />
      <div className="table-block__main-img">
        <img src={ContainerImg} alt="Container img" />
      </div>
      <h2>CONTAINERS</h2>
      <table>
        <thead>
          <tr>
            <th>Container Nr.</th>
            <th>Size / type</th>
            <th>Last movement</th>
            <th>Date {`&`} time</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {containers.map(cont => (
            <tr key={cont.containerNumber + cont.type}>
              <td>
                <button type="button" onClick={() => onClickContainer(cont)}>
                  {cont.containerNumber}
                </button>
              </td>
              <td>{cont.type}</td>
              <td>{cont.movementDescription}</td>
              <td>{parseDate(cont.date)}</td>
              <td>{cont.location}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
