import React, { useEffect } from "react";

import "./modal.styles.scss";

export default function Modal({ children, closeModal }) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  return (
    <div className="modal">
      <div className="modal__background" onClick={closeModal} />
      <div className="modal__content">
        <button
          className="modal__close-button"
          type="button"
          onClick={closeModal}
        />
        {children}
      </div>
    </div>
  );
}
