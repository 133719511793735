import React from "react";
import classNames from "classnames";

import ShipImg from "../../assets/img/container_marguisa.jpg";
import CargoShipIcon from "../../assets/icons/cargo-ship.svg";
import DownloadIcon from "../../assets/icons/download.svg";

import "./timeline.style.scss";

const location = window.location.href;

const SHIP_STATUS = {
  INCOMING: "incoming",
  PRESENT: "present",
  DEPARTED: "departed"
};

const getShipStatus = ({ date, originDate, destinyDate }) => {
  if (originDate && date < originDate) {
    return SHIP_STATUS.INCOMING;
  } else if (date < destinyDate || !destinyDate) {
    return SHIP_STATUS.PRESENT;
  } else {
    return SHIP_STATUS.DEPARTED;
  }
};

const parseBoatTravel = (boats, lastMovement) => {
  const date = new Date(lastMovement);
  const travel = [
    {
      port: boats[0].portOfLoading,
      status: getShipStatus({
        date,
        destinyDate: new Date(boats[0].originDate)
      })
    }
  ];
  boats.forEach((boat, index) => {
    travel.push({
      status: getShipStatus({
        date,
        originDate: new Date(boat.originDate),
        destinyDate: new Date(boat.destinyDate)
      })
    });
    const nextBoatTravel = boats[index + 1];
    travel.push({
      port: boat.portOfDestination,
      status: getShipStatus({
        date,
        originDate: new Date(boat.destinyDate),
        destinyDate: nextBoatTravel && new Date(nextBoatTravel.originDate)
      })
    });
  });
  return travel;
};

export default function Timeline({ boats, searchPattern, lastMovement }) {
  const booking = searchPattern.booking
    ? `BOOKING NR. ${searchPattern.booking}`
    : "";
  const bill = searchPattern.bill ? `BILL OF LADING ${searchPattern.bill}` : "";
  const container = searchPattern.container
    ? `CONTAINER NR. ${searchPattern.container}`
    : "";
  const travel = parseBoatTravel(boats, lastMovement);



  const filterPDF = searchPattern.booking
    ? "booking_number"
    : searchPattern.container
    ? "container_number"
    : "bill_of_lading";

  return (
    <div className="timeline">
      <h1>{booking || bill || container}</h1>
      <div className="timeline__main-img">
        <img src={ShipImg} alt="Ship Img" />
      </div>
      <h2>TIMELINE</h2>
      <ul
        className={classNames("ports-list", {
          "ports-list--big": travel.length > 5
        })}
      >
        {travel.map(tra => {
          const className = tra.port ? "port" : "travel";
          return (
            <li
              className={`${className} ${tra.status}`}
              key={Math.random().toString() + tra.port}
            >
              {tra.port && <p>{tra.port}</p>}
              {tra.status === SHIP_STATUS.PRESENT && (
                <img src={CargoShipIcon} alt="ship" />
              )}
            </li>
          );
        })}
      </ul>
      <div className="download">
        <a
          href={`${location}s/api/ships/render/?${filterPDF}=${searchPattern.booking ||
            searchPattern.bill ||
            searchPattern.container}`}
          target="__blank"
        >
          Download .pdf
          <img
            src={DownloadIcon}
            alt="download icon"
          />
        </a>
      </div>
    </div>
  );
}
