import React, { useState } from "react";
import classnames from "classnames";

import SearchBackground from "../../assets/backgrounds/search-background.jpg";

import "./searchBlock.styles.scss";

// const bookingMock = "CIM002226";

const bookingRegex = {
  error: "Field format: ABC123456",
  placeholder: "ABC123456",
  regex: /^[a-zA-Z]{3}[0-9]{6}$/
};
const containerRegex = {
  error: "Field format: ABCD1234567",
  placeholder: "ABCD1234567",
  regex: /^[a-zA-Z]{4}[0-9]{7}$/
};
const billRegex = {
  error: "Field format: ABC-123456",
  placeholder: "ABC-123456",
  regex: /^[a-zA-Z]{3}-[0-9]{6}$/
};

const Input = ({ id, label, value, disabled, placeholder, onChange }) => (
  <div
    className={classnames("form-group", {
      "form-group--disabled": disabled
    })}
  >
    <label htmlFor={id}>{label}</label>
    <input
      type="text"
      id={id}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
    />
  </div>
);

export default function SearcBlock({ onSearch }) {
  const [error, setError] = useState("");
  const [booking, setBooking] = useState("");
  const [bill, setBill] = useState("");
  const [container, setContainer] = useState("");
  function onSubmit(event) {
    event.preventDefault();
    onSearch({ booking, bill, container });
  }
  function onChange(value, callback, conf) {
    if (value && !conf.regex.test(value)) {
      setError(conf.error);
    } else {
      setError("");
    }
    callback(value);
  }
  function emptyFields() {
    setError("");
    setBooking("");
    setBill("");
    setContainer("");
  }
  return (
    <div className="search-block">
      <div className="background">
        <img src={SearchBackground} alt="" />
      </div>
      <h2>
        Please, type in the <span>Booking Nr.</span> or the{" "}
        <span>Container Nr.</span> or the <span>Bill of Lading.</span>
      </h2>
      <form onSubmit={onSubmit}>
        <div className="form">
          <Input
            id="booking"
            label="Booking Nr."
            value={booking}
            disabled={bill || container}
            placeholder={bookingRegex.placeholder}
            onChange={({ target }) =>
              onChange(target.value, setBooking, bookingRegex)
            }
          />
          <Input
            id="container-nr"
            label="Container Nr."
            value={container}
            disabled={bill || booking}
            placeholder={containerRegex.placeholder}
            onChange={({ target }) =>
              onChange(target.value, setContainer, containerRegex)
            }
          />
          <Input
            id="bill"
            label="Bill of Lading"
            value={bill}
            disabled={booking || container}
            placeholder={billRegex.placeholder}
            onChange={({ target }) =>
              onChange(target.value, setBill, billRegex)
            }
          />
          <button
            className="btn"
            type="submit"
            disabled={(!bill && !booking && !container) || error}
          >
            SEARCH
          </button>
          <button
            className="btn"
            type="button"
            onClick={emptyFields}
            disabled={!bill && !booking && !container}
          >
            CLEAR
          </button>
        </div>
      </form>
      <div className="error">{error}</div>
    </div>
  );
}
