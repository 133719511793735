import React from "react";

import { parseDate } from "../../utils/date";

import CraneImg from "../../assets/img/crane.png";

import "./tables.style.scss";
import DownloadIcon from "../../assets/icons/download.svg";

const location = window.location.href;

export default function ContainerMovementsTable({ containerMovements }) {
  

  return (
    <div className="table-block table-container">
      <h1>
        CONTAINER NR.{" "}
        {containerMovements[0] && containerMovements[0].containerNumber}
      </h1>
      <div className="table-block__main-img">
        <img src={CraneImg} alt="Crane img" />
      </div>

      <h2>SHIPMENT PROGRESS</h2>
      <table>
        <thead>
          <tr>
            <th>Movement</th>
            <th>Date {"&"} time</th>
            <th>Location</th>
            <th>Terminal / Depot</th>
            <th>Mode</th>
          </tr>
        </thead>
        <tbody>
          {containerMovements.map(cont => (
            <tr key={cont.containerNumber + cont.type}>
              <td>{cont.movement}</td>
              <td>{parseDate(cont.date)}</td>
              <td>{cont.location}</td>
              <td>{cont.terminal}</td>
              <td>{cont.mode === "T" ? " By land" : "By sea"}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {(containerMovements[0] && containerMovements[0].containerNumber) && (
          <div className="download">
          <a href={`${location}s/api/containers/render/?container_number=${containerMovements[0] && containerMovements[0].containerNumber}`} target="__blank">
            Download .pdf
            <img src={DownloadIcon} alt="download icon"/>
          </a>
        </div>
      )}
  
    </div>
  );
}
