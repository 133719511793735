const apiRoot = "s/api/v1/";

const boatListParser = boatList => {
  return boatList.results.map(boat => ({
    ship: boat.ship,
    journey: boat.journey,
    portOfLoading: boat.port_of_loading,
    originDate: boat.etd_origin,
    portOfDestination: boat.port_of_destination,
    destinyDate: boat.eta_destiny
  }));
};

const containersListParser = containers => {
  return containers.results.map(cont => ({
    containerNumber: cont.container_number,
    type: cont.type,
    date: cont.date,
    movementCode: cont.movement_code,
    movementDescription: cont.movement_description,
    location: cont.location
  }));
};

const containerMovementsParser = movements => {
  return movements.results.map(mov => ({
    containerNumber: mov.container_number,
    movement: mov.movement_description,
    date: mov.date,
    location: mov.location,
    terminal: mov.terminal_location,
    mode: mov.sn_maritime
  }));
};

async function fetchInfo(url, parser) {
  const data = await fetch(url, {
    method: "GET",
    headers: new Headers(),
    mode: "cors",
    cache: "default"
  });
  const json = await data.json();
  return parser(json);
}

export const getInfo = async ({ booking, bill, container }) => {
  const queryParams = [
    booking ? `booking_number=${booking}` : "",
    container ? `container_number=${container.replace(" ", "-")}` : "",
    bill ? `bill_of_lading=${bill}` : ""
  ].join("");
  const boatsQuery = `${apiRoot}bookingtracking/?${queryParams}`;
  const containersQuery = `${apiRoot}latest-container-movements/?${queryParams}`;

  try {
    const [boats, containers, lastMovement] = await Promise.all([
      fetchInfo(boatsQuery, boatListParser),
      fetchInfo(containersQuery, containersListParser)
    ]);

    return { boats, containers, lastMovement };
  } catch (e) {
    throw e;
  }
};

export const getContainerMovements = async containerNumber => {
  try {
    const query = `${apiRoot}container-movements/?container_number=${containerNumber}`;
    const containerMovements = await fetchInfo(query, containerMovementsParser);
    return containerMovements;
  } catch (e) {
    throw e;
  }
};
