import React from "react";
import "./infoBlock.styles.scss";

export default function InfoBlock() {
  return (
    <div className="info-block">
      <h2>TRACKING SERVICE</h2>
      <p>
        At Marguisa we have a strong commitment with the shipping business, and
        therefore we are very conscious of the importance that knowing the
        current status of their shipments has to our customers.
      </p>
      <p>
        If you desire to know the precise location of a container, please fill
        in its number in the relevant field of this form. Otherwise if you want
        to know the status of all the containers included in one Bill of Lading,
        please, write its number in the corresponding form´s field.
      </p>
      <p>
        This form is intended only as a tool to know the whereabouts of
        shipments, if you need to get in touch with us, please do it through the{" "}
        <a href="http://www.marguisa.com/contact/">Contact</a> page. And if you
        are looking for information about our international network of agents,
        please go on to the{" "}
        <a href="http://www.marguisa.com/offices/">Agents</a> page.
      </p>
      <div className="row">
        <a href="http://www.marguisa.com/" className="btn">
          HOMEPAGE
        </a>
      </div>
    </div>
  );
}
