import React from "react";
import { parseDate } from "../../utils/date";

import ClockImg from "../../assets/img/clock.png";

import "./boatListTable.style.scss";
import "./tables.style.scss";

export default function BoatListTable({ boats, lastMovement }) {
  const movementDate = new Date(lastMovement);

  function getDateColor(date) {
    return new Date(date) > movementDate ? "red" : "blue";
  }
  return (
    <div className="table-block">
      <div className="bar" />
      <div className="table-block__main-img">
        <img src={ClockImg} alt="Clock img" />
      </div>
      <h2>SCHEDULE</h2>
      <table>
        <thead>
          <tr>
            <th>Vessel</th>
            <th>Voyage Nr.</th>
            <th>Port of Loading</th>
            <th>Departure</th>
            <th>Port of Discharge</th>
            <th>Arrival</th>
          </tr>
        </thead>
        <tbody>
          {boats.map(boat => (
            <tr key={boat.ship + boat.originDate}>
              <td>{boat.ship}</td>
              <td>{boat.journey}</td>
              <td>{boat.portOfLoading}</td>
              <td className={getDateColor(boat.originDate)}>
                {parseDate(boat.originDate)}
              </td>
              <td>{boat.portOfDestination}</td>
              <td className={getDateColor(boat.destinyDate)}>
                {parseDate(boat.destinyDate)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="schedule-info">
        <div className="row">
          <div className="blue">{"Blue: current date & time"}</div>
          <div className="red">{"Red: estimated date & time"}</div>
        </div>
        <p className="row">
          <span>*</span>
          {"All Dates & times are local."}
        </p>
        <p className="row">
          <span>*</span>
          {
            "Any information given regarding the estimated time are estimates only, and are subject to change without notice."
          }
        </p>
        <p className="row">
          <span>*</span>
          {"If your shipment is blank now, please "}
          <a href="http://www.marguisa.com/contact/"> contact </a>
          {" with Marguisa for more details."}
        </p>
      </div>
    </div>
  );
}
