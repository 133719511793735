import React from "react";

// IMAGES
import MainLogo from "../../assets/logos/main-logo.png";
import MainLogoWhite from "../../assets/logos/main-logo--white.png";

// ICONS
import HomeIcon from "../../assets/icons/home.svg";
import ContactsIcon from "../../assets/icons/contacts.svg";
import EmailIcon from "../../assets/icons/email.svg";
import LocationIcon from "../../assets/icons/location.svg";

import "./layout.styles.scss";

export default function Layout({ children }) {
  return (
    <div className="layout">
      <header className="header">
        <a className="main-logo" href="http://www.marguisa.com/">
          <img src={MainLogo} alt="Main Logo" />
        </a>
        <a href="http://www.marguisa.com/" className="header__right">
          <img className="icon" src={HomeIcon} alt="Home Icon" />
          <p>Homepage</p>
        </a>
      </header>
      <main>{children}</main>
      <footer className="footer">
        <a href="http://www.marguisa.com/" className="footer__logo">
          <img src={MainLogoWhite} alt="Marguisa logo" />
        </a>
        <ul className="footer__contact">
          <li>
            <a href="mailto: marguisa@marguisa.com">
              marguisa@marguisa.com
              <div className="icon">
                <img src={EmailIcon} alt="Email Icon" />
              </div>
            </a>
          </li>
          <li>
            <a href="https://goo.gl/maps/c7euA9ZKhADYM2rW9">
              C/ Villanueva, 5 1º 28001 Madrid – Spain
              <div className="icon">
                <img src={LocationIcon} alt="Location Icon" />
              </div>
            </a>
          </li>
          <li>
            <a href="http://www.marguisa.com/offices/">
              Click here to access out Agents network
              <div className="icon">
                <img src={ContactsIcon} alt="Contacts Icon" />
              </div>
            </a>
          </li>
        </ul>
        <div className="footer__copyright">
          Copyright © 2021{" "}
          <span>
            Marguisa - Shipping Cargoes, Managing Expectations |{" "}
            <a href="http://www.marguisa.com/aviso-legal/">Aviso legal</a> -{" "}
            <a href="http://www.marguisa.com/en/legal-notice/">Legal notice</a>
          </span>
        </div>
      </footer>
    </div>
  );
}
