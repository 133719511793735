import React from "react";
import Spinner from "./spinner";

import "./loader.styles.scss";

export default function Loader() {
  return (
    <div className="loader">
      <Spinner />
    </div>
  );
}
